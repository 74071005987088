import React from 'react'
import './ContactForm.css'

const ContactForm = () => {
  return (
      <div className='contact-form-content'>
          <form>
              <div className='name-container'>
                  <input type='text' name='firstname' placeholder='Fornavn' />
                  <input type='text' name='lastname' placeholder='Efternavn' />
              </div>

              <input type='text' name='email' placeholder='Email' />
              <textarea type="text" name='message' placeholder='Besked' rows={3} />

              <button>SEND</button>
          </form>
    </div>
  )
}

export default ContactForm