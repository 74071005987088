import React from 'react'
import './Hero.css'

const Hero = () => {
  return (
      <section className='hero-container' id='hjem'>
          <div className='hero-content'>
              <h2>Opbygning af digitale oplevelser, der inspirerer</h2>
              <p>
                  Passioneret frontend-udvikler, der transformerer ideer til
                  Seanless og Visuelt fantastiske webløsninger
              </p>
          </div>

          <div className='hero-img'>
              <div>
                  <div className='tech-icon'>
                      <img src='./assets/images/html.png' alt='' />
                  </div>
                  <img src='./assets/images/Uden-baggrund.png' alt='' />
              </div>

              <div>
                  <div className='tech-icon'>
                      <img src='./assets/images/css.png' alt='' />
                  </div>
                  <div className='tech-icon'>
                      <img src='./assets/images/react.png' alt='' />
                  </div>
                  <div className='tech-icon'>
                      <img src='./assets/images/js.png' alt='' />
                  </div>
              </div>
          </div>
    </section>
  )
}

export default Hero