import React from 'react'
import './ContactMe.css'
import ContactInfoCard from './ContactInfoCard/ContactInfoCard'
import ContactForm from './ContactForm/ContactForm'

const ContactMe = () => {
  return (
    <section className='contact-container' id='kontaktmig'>
      <h5>Contact Me</h5>

      <div className='contact-content'>
        <div style={{ flex: 1 }}>
          <ContactInfoCard
            iconUrl="./assets/images/email-1572-svgrepo-com.svg"
            text="michaelkrogholm@gmail.com"
          />
          <ContactInfoCard
            iconUrl="./assets/images/github-142-svgrepo-com.svg"
            text="https://github.com/michaelkp123"
          />
        </div>
        <div style={{ flex: 1 }}>
          <ContactForm/>
        </div>
      </div>
    </section>
  )
}

export default ContactMe