import React, { useState } from 'react'
import './Navbar.css'
import MobileNav from './MobileNav/MobileNav'

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

  return (
      <>
          <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

          <nav className='nav-wrapper'>
              <div className='nav-content'>
                  <h1 className='logo'>Michael kp</h1> 
                  
                  <ul>
                      <li>
                          <a className='menu-item' href='#hjem'>Hjem</a>
                      </li>
                      <li>
                          <a className='menu-item' href='#skill'>Skills</a>
                    </li>
                    <li>
                          <a className='menu-item' href='#erhvervserfaring'>Erhvervserfaring</a>
                      </li>
                      <li>
                          <a className='menu-item' href='#projekter'>Projekter</a>
                      </li>
                      <li>
                          <a className='menu-item' href='#kontaktmig'>Kontakt mig</a>
                      </li>

                  </ul>

                  <button class="menu-btn" onClick={toggleMenu}>
                      <span
                          class={"material-symbols-outlined"}
                          style={{ fontSize: "1.8rem" }}
                      >
                          {openMenu ? "close" : "menu"}
                      </span>
                  </button>
              </div>
        </nav>
      </>
  )
}

export default Navbar