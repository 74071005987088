export const SKILLS = [
    {
        title: "Frontend",
        icon: "./assets/images/front-end-programming.png",
        skills: [
            { skill: "HTML5", percentage: "80%" },
            { skill: "CSS3 (Sass/Tailwind)", percentage: "90%" },
            { skill: "Javascript", percentage: "75%" },
            { skill: "React.js", percentage: "80%" },
            { skill: "PHP", percentage: "80%" },
            { skill: "Bootstrap", percentage: "80%" }
        ],
    },
    {
        title: "Backend",
        icon: "./assets/images/software.png",
        skills: [
            { skill: "My SQL", percentage: "80%" },
            { skill: "MongoDB", percentage: "60%" },
            { skill: "GraphQL", percentage: "50%" }
        ],
    },
    {
        title: "Tools",
        icon: "./assets/images/tools.png",
        skills: [
            { skill: "Visual Studio Code", percentage: "100%" },
            { skill: "Git & Github", percentage: "60%" },
            { skill: "npm terminal", percentage: "80%" }
        ],
    },
    {
        title: "Soft Skills (TT38)",
        icon: "./assets/images/team-leader.png",
        skills: [
            { skill: "Målrettet", percentage: "100%" },
            { skill: "Optimistisk", percentage: "95%" },
            { skill: "Retfærdig", percentage: "90%" },
            { skill: "Værdifast", percentage: "85%" },
            { skill: "Idérig", percentage: "80%" },
            { skill: "Fokuserende", percentage: "80%" },
            { skill: "Fremtidsorienteret", percentage: "75%" },
            { skill: "Problemløser", percentage: "75%" }
        ],
    }
]

export const WORK_EXPERIENCE = [
    {
        title: "Webudvikler ved Himmerlands Pejsecenter",
        date: "August 2018 - Juni 2020",
        responsibilities: [
            "Oprette produkter i Prestashop (CMS)",
            "SEO opgaver såsom tekster",
            "API arbejde mellem Prestashop og Pejsefabrikker"
        ],
    },
    {
        title: "Webudvikler ved Urban-Hald",
        date: "Januar 2022 - Oktober 2023",
        responsibilities: [
            "Produktberegner til Trægulv og Facadepuds",
            "Rettelse af fejl på hjemmesiden i Dandomain",
            "API arbejde mellem Dandomain og Bewise api"
        ],
    },
    {
        title: "Webudvikler ved Maulund",
        date: "Juni 2023 - Januar 2024",
        responsibilities: [
            "Oprette produkter i Shopify (CMS)",
            "Kode nye løsninger i Liquid",
            "Lav en app til Shopify til 360 graders produkt billeder"
        ],
    },
]