import React from 'react'
import './ContactInfoCard.css'

const ContactInfoCard = ({iconUrl, text}) => {
  return (
    <div className='contact-details-card'>
      <a target='_blank' rel="noreferrer" className='link' href={text}>
          <div className='icon'>
              <img src={iconUrl} alt={text} />
          </div>

        <p>{text}</p>
      </a>
    </div>
  )
}

export default ContactInfoCard