import React from 'react'
import './MobileNav.css'

const mobileNav = ({isOpen, toggleMenu}) => {
  return (
    <>
    <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
    >
        <div className='mobile-menu-container'>
            <h1 className='logo'>Michael</h1>

            <ul>
                <li>
                    <a className='menu-item' href='#hjem'>Hjem</a>
                </li>
                <li>
                    <a className='menu-item' href='#skill'>Skills</a>
                </li>
                <li>
                    <a className='menu-item' href='#erhvervserfaring'>Erhvervserfaring</a>
                </li>
                <li>
                    <a className='menu-item' href='#projekter'>Projekter</a>
                </li>
                <li>
                    <a className='menu-item' href='#kontaktmig'>Kontakt mig</a>
                </li>

            </ul>
        </div>
    </div>
</>
  )
}

export default mobileNav